import React from "react";
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {Tabs} from "../../interface/Tabs/Tabs";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {Link, useNavigate} from "react-router-dom";
import {Persona} from "../../interface/Persona";
import {filters} from "./filters"
import {FETCH_DEALS,DELETE_DEAL} from "./queries";
import {Pillola} from "../../interface/Pillole";
import {PencilIcon,TrashIcon} from "@heroicons/react/outline";
import {format} from "date-fns";
// @ts-ignore
import numeral from "numeral"
import {find, get} from "lodash";
import {usePlatformContext} from "../../context/PlatformContext";
import {useMutation} from "@apollo/client";
import {useFlash} from "../../context/FlashContext";
import {DataContainersFiltersHorizontal} from "../../interface/DataContainer/DataContainerFilters";


export function DealsDirectoryContainer() {
    const {filtersQuery} = useDataContainerContext();
    const {addPane, removePaneByID} = usePlatformContext()
    const navigate = useNavigate()
    const flash = useFlash()

    const {applyFilters} = useDataContainerContext()

    const [deleteDeal,{data,error,loading}] = useMutation(DELETE_DEAL)

    const handleDeleteDeal = (id: string) => {
        // warn before deleting or provide an undo
        deleteDeal({variables: {id: id}})
            .then((res) => {
                console.log("res",res)
                navigate("/deals/directory?t=directory")
                applyFilters()
                flash("deal deleted successfully","success")
            })
            .catch((err) => {
                console.log("err",err)
                flash("failed to delete deal","error")
            })
    }

    return (
        <section className="px-5">
            <div className="max-w-full">
                <DataContainersFiltersHorizontal/>
            </div>
            <div className="w-full">
                <DataGridGraphQL
                    loading={false}
                    query={FETCH_DEALS}
                    variables={{
                        search: {
                            bucket: 'deals'
                        }
                    }}
                    root="search"
                    property="items"
                    filters={filtersQuery}
                    sort={[
                        {key: "date", dir: "desc"}
                    ]}
                    entityName="deals"
                    headers={[
                        {
                            title: "Company Name",
                            dataIndex: "name",
                            key: "name",
                            render: (value) => {
                                return (
                                    <Link to={['/organizations/directory/orgs', value.target.id].join("/")}>
                                        <Persona text={value?.target?.name} size="xs"
                                                 imageURL={value?.target?.profile_image_url}/>

                                    </Link>
                                )
                            }
                        },
                        {
                            title: "HQ Country",
                            dataIndex: "country",
                            key: "country",
                            render: (value) => {
                                const offices = find(get(value, 'target.offices', []), {is_hq_primary: true})
                                console.log(offices)
                                return get(offices, 'country.name', 'Not set')
                            }
                        },
                        {
                            title: "Date",
                            dataIndex: "date",
                            key: "date",
                            render: (value) => value?.date ? format(new Date(value?.date), 'PPP') : "Undisclosed"
                        },
                        {
                            title: "Amount",
                            dataIndex: "amount",
                            key: "amount",
                            render: (value) => {
                                return <p
                                    className="uppercase">{value.currency_code} {numeral(value.amount).format(`($ 0.00 a)`)}</p>
                            }
                        },
                        {
                            title: "Investors",
                            dataIndex: "investors",
                            key: "investors",
                            render: (value) => value.investors.map((i: { name: any; }) => i.name).join(", ")
                        },
                        {
                            title: "Stage",
                            dataIndex: "stage",
                            key: "stage",
                            render: (value) => <Pillola text={value.stage} variant={"ghost"}/>
                        },
                        {
                            title: "Type",
                            dataIndex: "type",
                            key: "type",
                            render: (value) => <Pillola text={value.type} variant={"ghost"}/>
                        },
                        {
                            title: "Sectors",
                            dataIndex: "target.sectors.name",
                            key: "target.sectors.name",
                            render: (value) => (get(value, 'target.sectors', []) || []).map((i: any) => i.name).join(", ")
                        },
                        {
                            title: "Country",
                            dataIndex: "country",
                            key: "target.ops_countries.name",
                            render: (value) => (get(value, "target.ops_countries", []).map((c: any) => c.name)).join(", ")
                        },
                        {
                            title: "",
                            dataIndex: "actions",
                            key: "actions",
                            render: (value) => {
                                return (
                                    <>
                                        <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
                                            <PencilIcon className="w-5 h-5 text-gray-500" onClick={() => {
                                                navigate(`/deals/directory/deals/${value.id}/edit`)
                                            }}/>
                                            <TrashIcon className="w-5 h-5 text-gray-500" onClick={() => {
                                                handleDeleteDeal(value.id)
                                                navigate("/")
                                            }}/>
                                        </div>
                                    </>
                                )
                            }
                        }
                    ]}
                    data={[]}
                />
            </div>
        </section>
    )
}

export default function DealsContainer() {
    const nav = {
        "directory": {
            title: 'Directory',
            key: 'directory',
            pane: <DealsDirectoryContainer/>
        }
    }

    return (
        <DataContainerProvider filters={filters}>
            <div className="flex-grow flex flex-row">
                <div className="flex-grow">
                    <Tabs
                        title="Deals"
                        index={'directory'}
                        tabs={nav}
                    />
                </div>
            </div>
        </DataContainerProvider>
    )
}
