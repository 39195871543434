import classNames from "classnames";
import {InformationCircleIcon} from "@heroicons/react/solid";
import React from "react";

export const FilterTitle = ({
                                title,
                                help,
                                overrideStyle = '',
                                active = false,
                                loading = false
                            }: {
    title: string,
    help?: string,
    overrideStyle?: string,
    active?: boolean,
    loading?: boolean
}) => {
    const cls = classNames({
        "text-sm text-bs-light-black flex items-center flex-grow": true,
    }, overrideStyle)
    return (
        <p className={cls}>
            <span>{title}</span>
            {
                help && (
                    <InformationCircleIcon className="ml-2 h-5 w-5 text-bs-light-black"/>
                )
            }
        </p>
    )
}