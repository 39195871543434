import {ChangeEvent} from "react";
import {isNumber } from "lodash"
const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 })

export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "search",
                title: "Organisation name",
                key: "name",
                id: "name",
                desc: "Search for organizations by their name to quickly locate specific entries or match similar names using the search field for efficient filtering."
            },
            {
                type: "select-or",
                title: "Operating Status",
                key: "operating_status.keyword",
                id: "operating_status.keyword",
                options: [
                    {key: "active", value: "y", title: "Active"},
                    {key: "inactive", value: "n", title: "Inactive"},
                    {key: "review", value: "c", title: "Under review"},
                ],
                desc: "Filter organizations based on their current operational status, including active, inactive, or under review, to refine your search results."
            },
            {
                type: "select-or",
                title: "Type",
                key: "type.keyword",
                id: "type.keyword",
                options: [
                    {key: "startup", value: "startup", title: "Startup"},
                    {key: "investor", value: "investor", title: "Investor"},
                    {key: "hub", value: "hub", title: "Hub"},
                ],
                desc: "Select organization type, such as startup, investor, or hub, to tailor your results to specific categories within the database."
            },
            {
                type: "select-or",
                title: "Specifier",
                bucket: "organizations",
                key: "specifier.keyword",
                id: "specifier.keyword",
                options: [],
                dynamic: true,
                desc: "Dynamically select specific attributes of organizations to further narrow down your search results with detailed categorization."
            },
            {
                type: "range",
                title: "Founded between",
                key: "founded",
                id: "founded",
                extras: {
                    type: "month"
                },
                desc: "Specify a date range to filter organizations based on their founding year for precise time-based analysis."
            }
        ]
    },
    {
        title: "AgBase",
        filters: [
            {
                type: "exists",
                title: "AgBase Specific",
                key: "agbase_category.name",
                id: "agbase_category.name",
                dynamic: false,
                bucket: 'organizations',
                options: [],
                desc: "Filter organizations that have specific AgBase attributes to focus on entries relevant to agricultural base information."
            },
            {
                type: "select-or",
                title: "Category",
                key: "agbase_category.name.keyword",
                id: "agbase_category.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Dynamically choose a category for AgBase organizations to target specific groups or classifications within the agricultural domain."
            },
            {
                type: "select-or",
                title: "Value Chain",
                key: "agbase_value_chain.name.keyword",
                id: "agbase_value_chain.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Select relevant value chains to explore AgBase organizations contributing to specific parts of the agricultural supply chain."
            },
            {
                type: "select-or",
                title: "Custom Visibility",
                key: "agbase_visibility.name.keyword",
                id: "agbase_visibility.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Filter based on custom visibility options to focus on specific AgBase organizations with tailored visibility attributes."
            },
            {
                type: "select-or",
                title: "Regional HQs",
                key: "offices_hq_regional.country.name.keyword",
                id: "offices_hq_regional.country.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Find organizations based on the location of their regional headquarters for geographical targeting within AgBase."
            },
        ]
    },
    {
        title: "Sectors",
        filters: [
            {
                type: "select-or",
                title: "Sectors",
                key: "sectors.name.keyword",
                id: "sectors.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Filter organizations by sectors to target specific industries or fields, dynamically based on sector data."
            },
            {
                type: "select-or",
                title: "Sub-Sectors",
                key: "sub_sectors.name.keyword",
                id: "sub_sectors.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Select sub-sectors to refine your search within smaller subdivisions of industries for enhanced filtering."
            },
            {
                type: "select-or",
                title: "Technologies",
                key: "technologies.name.keyword",
                id: "technologies.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Filter organizations based on the technologies they are associated with for a focused technological analysis."
            },
        ]
    },
    {
        title: "Geography",
        filters: [
            {
                type: "select-or",
                title: "Region",
                key: "ops_countries.region.keyword",
                id: "ops_countries.region.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Filter by region to identify organizations operating within specific geographical areas on a macro scale."
            },
            {
                type: "select-or",
                title: "Sub-Region",
                key: "ops_countries.sub_region.keyword",
                id: "ops_countries.sub_region.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Select sub-regions to refine your geographical filter and focus on more specific locations."
            },
            {
                type: "select-or",
                title: "Operating Countries",
                key: "ops_countries.name.keyword",
                id: "ops_countries.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Filter organizations by the countries where they operate for a country-specific analysis of operations."
            },
        ]
    },
    {
        title: "Impact Metrics",
        filters: [
            {
                title: "SDG name",
                type: "select-or",
                key: "sdgs.name.keyword",
                id: "sdgs.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Filter organizations by their association with specific Sustainable Development Goals (SDGs) for impact-based analysis."
            },
            {
                title: "Impact metrics",
                type: "select-or",
                key: "attributes.organization:ResourceTag/Impact",
                id: "attributes.organization:ResourceTag/Impact",
                dynamic: true,
                bucket: 'organizations',
                options: [],
                desc: "Select specific impact metrics to explore organizations contributing to defined social or environmental outcomes."
            }
        ]
    },
    {
        title: "Funding",
        filters: [
            {
                type: "select-or",
                title: "Investor Name",
                key: "deals.investors.name.keyword",
                id: "deals.investors.name.keyword",
                dynamic: true,
                options: [],
                bucket: "organizations",
                desc: "Filter organizations by the names of their investors to analyze funding sources and connections."
            },
            {
                title: "Funding stage",
                type: "select-or",
                key: "deals.stage",
                id: "deals.stage",
                dynamic: true,
                options: [],
                bucket: "organizations",
                desc: "Filter organizations by the funding stage to understand their financial progression and growth stage."
            },
            {
                title: "Doesn't have funding",
                type: "boolean",
                key: "no_funding",
                id: "no_funding",
                desc: "Include organizations that have not received any funding, focusing on those with no financial backing."
            },
            {
                title: "Funding received",
                type: "range",
                key: "total_funding",
                id: "total_funding",
                extras: {
                    onChange: (ev: ChangeEvent<HTMLInputElement>) => {
                        const number = ev.target.value.substring(1).replaceAll(",", "")
                        console.log(number, isNumber(parseInt(number)))
                        return number
                    },
                    render: (v: number) => {
                        return currencyFormatter.format(v)
                    }
                },
                desc: "Specify a funding range to filter organizations based on the total funding they have received over time."
            },
            {
                type: "exists",
                title: "Funding information available",
                key: "deals.investors.name",
                id: "deals.investors.name",
                desc: "Filter organizations that have available funding information to ensure data completeness and accuracy."
            }
        ]
    }
];
