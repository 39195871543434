import React, {useRef, useState} from "react";
import {Button} from "../Button/Button";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import {IDataContainerFilter, useDataContainerContext} from "./context/DataContainerContext";
import classNames from "classnames";
import FilterFactory, {IFilter} from "./filters/FilterFactory";
import _, {find, first, flatten, get} from "lodash";
import {useLocalStorage, useOnClickOutside} from "usehooks-ts";
import {
    IoChevronForward,
    IoChevronForwardCircle,
    IoChevronForwardOutline,
    IoChevronForwardSharp,
    IoClose,
    IoFilter
} from "react-icons/io5";
import {createPortal} from "react-dom";
import {useRect} from "../../utils/hooks/useRect";
import Fuse from "fuse.js"
import {FiX} from "react-icons/fi";
import {RxDividerVertical} from "react-icons/rx";

const countActiveFilters = (filters: any[], actives: string[]): number => {
    const a = filters?.filter((f) => actives.includes(f.key));
    return a ? a.length : 0;
}

const getCurrentFiltersPillValues = (filter: IDataContainerFilter, def: IFilter) => {
    switch (filter.type) {
        case "select-or":
            //@ts-ignore
            if (def?.dynamic) {
                // @ts-ignore
                return filter.value.length > 5
                    // @ts-ignore
                    ? `${filter.value.slice(0, 5).join(", ")} +${filter.value.length - 5}`
                    // @ts-ignore
                    : filter.value.join(", ")
            }
            // @ts-ignore
            const actives = filter.value.map((v) => {
                // @ts-ignore
                const option = find(def?.options, {value: v})
                //@ts-ignore
                return option.title
            })

            if(actives.length > 5) {
                return actives.slice(0, 5).join(", ")
            }

            return actives.join(", ")
        case "range":
            const render = def.extras?.render ? def.extras.render : (v: any) => v
            //@ts-ignore
            if (filter.value?.gte && filter.value?.lte) {
                //@ts-ignore
                return `from ${render(filter.value.gte)} to ${render(filter.value.lte)}`
            }
            return ""
        default:
            return filter.value
    }
}

const FilterHorizontalPill = ({ filter, filters }: any) => {
    const [filtersRect, filtersRef] = useRect()
    const containerRef = useRef(null)
    const [isCardVisible, setIsCardVisible] = useState<boolean>(false)
    useOnClickOutside(containerRef, () => {
        setIsCardVisible(false)
    })

    const category = find(filters, {filters: [{key: filter.key}]})
    if (!category) return <p>ERR</p>;
    const def = find(category.filters, {key: filter.key})

    return (
        <div ref={filtersRef}>
            <button className="flex gap-2 items-center px-3 py-1 rounded-md border whitespace-nowrap" onClick={(ev) => {
                ev.preventDefault()
                setIsCardVisible(true)
            }}>
                <span className="text-gray-500">
                    {def.title}:
                </span> <span className="underline font-semibold">
                    {getCurrentFiltersPillValues(filter, def)}
                </span>
            </button>
            {
                isCardVisible && createPortal(
                    <div
                        className="absolute" ref={containerRef}
                        //@ts-ignore
                        style={{top: ((filtersRect?.top || 0) + (filtersRect?.height || 0) + 10), left: (filtersRect?.left || 0)}}
                    >
                        <DropDownFilterCard filter={{
                            ...def,
                            category: category.title
                        }} />
                    </div>,
                    document.body
                )
            }
        </div>
    )
}

interface IDataContainerDropDownFilter extends IDataContainerFilter {
    category: string;
    desc: string;
    title: string;
}

const DropDownFilterCard = ({filter}: any) => {
    const {
        filtersActive,
        filtersChanges,
        applyFilters,
        addFilter,
        removeAndApply,
        removeFilter
    } = useDataContainerContext();
    const isActive = filtersActive.filter((f) => f.key === filter.key).length > 0;
    return (
        <div
            className="shadow-md max-w-[300px] min-w-[300px] bg-white rounded-md border border-grey-200 flex gap-4 flex-col py-4">
            <div className="px-4 border-b">
                <p className="flex gap-1 items-center">
                                            <span className="text-gray-400">{
                                                filter.category
                                            }</span> <span
                    className="text-gray-400"><IoChevronForward/></span> {
                    filter.title
                }
                </p>
            </div>
            <div className="px-4">
                <p className="text-sm text-gray-500">
                    {filter.desc}
                </p>
            </div>
            <div className="px-4 grow">
                <FilterFactory
                    key={filter.key}
                    filtersChanges={filtersChanges}
                    filtersActive={filtersActive}
                    //@ts-ignore
                    filter={{...filter, addFilter, removeFilter}}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                />
            </div>
            <div className="px-4">
                <button
                    className="bg-bs-light-primary w-full px-4 py-2 rounded-md"
                    onClick={(ev) => {
                        ev.preventDefault()
                        applyFilters()
                    }}
                >
                    Apply filter
                </button>
            </div>

                {
                    isActive && (
                        <div className="px-4">
                            <button
                                className="bg-transparent border text-sm w-full px-4 py-2 rounded-md"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    removeAndApply({ type: filter.type, key: filter.key, value: ""})
                                }}
                            >
                                Clear this filter
                            </button>
                        </div>
                    )
                }
        </div>
    )
}

const DropDownFiltersContainer = ({containerRef, filtersRect}: any) => {
    const {
        filters,
        filtersActive,
        clearAllFilters
    } = useDataContainerContext();
    const [hoveringFilter, setHoveringFilter] = useState<IDataContainerDropDownFilter>()
    const [searchFilterQuery, setSearchFilterQuery] = useState<string>("")

    const flat = flatten(filters.map((category: any) => {
        const subFilters = get(category, 'filters', []);
        return subFilters.map((filter: any) => {
            return {
                ...filter,
                category: category.title
            }
        })
    }))

    const fuse = new Fuse(flat, {
        includeScore: true,
        keys: ['title', 'desc']
    })
    const visibleFilters = searchFilterQuery?.length > 0 ? fuse.search(searchFilterQuery).map((f) => f.item) : flat

    return (
        <div ref={containerRef} className="absolute text-sm"
            //@ts-ignore
             style={{top: ((filtersRect?.top || 0) + (filtersRect?.height || 0) + 10), left: (filtersRect?.left || 0)}}
        >
            <div className="flex gap-4">
                <div className="shadow-md min-w-[300px] bg-white rounded-md border border-gray-200">
                    <div className="px-2 pt-2">
                        <div className="w-full relative">
                            <input type="text"
                                   className="border py-1.5 px-2 w-full rounded-md"
                                   placeholder="Search filters..."
                                   value={searchFilterQuery}
                                   onChange={e => setSearchFilterQuery(e.target.value)}
                            />
                            {
                                searchFilterQuery.length > 0 && (
                                    <button
                                        className="absolute right-2.5 top-2.5"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            setSearchFilterQuery("")
                                        }}>
                                        <FiX/>
                                    </button>
                                )
                            }
                        </div>
                    </div>
                    <div
                        className="p-2 flex flex-col gap-1.5 max-h-[300px] overflow-y-scroll">
                        {visibleFilters.map((filter: any) => {
                            return (
                                <button
                                    key={filter.key}
                                    className={classNames({
                                        "group w-full px-2 py-1.5 rounded-md text-left flex items-center": true,
                                        "bg-bs-light-primary text-bs-light": filter.key === hoveringFilter?.key,
                                    })}
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        setHoveringFilter(filter)
                                    }}
                                    onMouseEnter={() => setHoveringFilter(filter)}
                                >
                                        <span
                                            className={classNames({
                                                "group-hover:text-bs-light-black": true,
                                                "text-bs-light": filter.key === hoveringFilter?.key,
                                                "text-gray-400": filter.key !== hoveringFilter?.key,
                                            })}>{filter.category}</span>
                                    <span
                                        className={classNames({
                                            "group-hover:text-bs-light-black": true,
                                            "text-bs-light": filter.key === hoveringFilter?.key,
                                            "text-gray-400": filter.key !== hoveringFilter?.key,
                                        })}>
                                        <IoChevronForwardOutline className="text-xs"/>
                                    </span> {filter.title}
                                </button>
                            )
                        })}
                    </div>
                    {
                        filtersActive.length > 0 && (
                            <div className="w-full p-2">
                            <button
                                className="p-2 rounded-md font-medium border whitespace-nowrap w-full text-center"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    clearAllFilters()
                                }}>
                                Clear all filters
                            </button>
                            </div>
                        )
                    }
                </div>
                {
                    hoveringFilter && (
                        <DropDownFilterCard filter={hoveringFilter}/>
                    )
                }
            </div>
        </div>
    )
}

export function DataContainersFiltersHorizontal() {
    const {
        filters = [],
        filtersActive,
        clearAllFilters
    } = useDataContainerContext();
    const [isPopupOpen, setPopupOpen] = useState(false);

    // @todo improve this, when you open left menu it doesn't move
    const containerRef = useRef(null);
    const [filtersRect, filtersRef] = useRect()
    useOnClickOutside(containerRef, () => {
        setPopupOpen(false)
    })

    if(filters.length < 1) {
        return <></>
    }

    return (
        <div className="w-full relative">
            <div className="py-2.5 flex gap-2 text-sm flex-wrap overflow-x-auto items-center">
                <div ref={filtersRef} onClick={(ev) => {
                    ev.preventDefault();
                    setPopupOpen(true);
                }}>
                    {
                        filtersActive.length > 0 && (
                                <button className="flex gap-2 items-center px-2 py-1 rounded-md border whitespace-nowrap">
                                    <IoFilter/> Filters <span
                                    className="bg-bs-light-primary px-2 rounded-md font-medium text-sm">
                            {filtersActive.length}</span>
                                </button>
                        )
                    }
                    {
                        filtersActive.length < 1 && (
                            <button
                                className="flex gap-2 items-center bg-bs-light-primary px-2 py-1 rounded-md whitespace-nowrap">
                                <IoFilter/> Add filters <IoChevronForward/>
                            </button>
                        )
                    }
                </div>
                {
                    filtersActive.length > 0 && (
                        <RxDividerVertical className="text-lg text-gray-300" />
                    )
                }
                {
                    isPopupOpen && createPortal(
                        <DropDownFiltersContainer
                            containerRef={containerRef}
                            filtersRect={filtersRect}
                        />, document.body)
                }
                {
                    filtersActive.map((f) => <FilterHorizontalPill key={f.key} filter={f} filters={filters} />)
                }
            </div>
        </div>
    )
}

export function DataContainerFilters() {
    const {
        filters,
        resetFilters,
        isDirty,
        filtersActive,
        filtersChanges,
        applyFilters,
        addFilter,
        removeFilter,
        clearAllFilters
    } = useDataContainerContext();
    const [isVisible, setIsVisible] = useLocalStorage('showFilters', true)
    const [state, setState] = useState({isVisible: isVisible});

    const setVisibilityState = (current: boolean) => {
        setIsVisible(current)
        setState({...state, isVisible: current})
    }

    const clsContainer = classNames({
        "filters relative shrink-0 max-h-screen bg-bs-light-gray-10 border-r-bs-light-border-light border-r min-h-screen": true,
        "w-[20rem] flex-grow max-w-[20rem]": state.isVisible,
        "w-10": !state.isVisible
    })
    const clsFilters = classNames({
        "p-4 pt-16 overflow-y-auto max-h-full": true,
        "hidden": !state.isVisible
    })

    const activeFiltersKeys = filtersActive.map((f) => f.key);
    const changesFilterKeys = filtersChanges.map((f) => f.key);
    const ref = useRef(null)

    return (
        <div ref={ref}
             className={clsContainer}>
            <button
                onClick={() => setVisibilityState(!state.isVisible)}
                onMouseEnter={() => setVisibilityState(true)}
                className="absolute rounded-full top-2 p-1.5 bg-gray-200 transition-all duration-[250ms] right-0 rounded-r-none">
                {
                    !state.isVisible && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                    )
                }
                {
                    state.isVisible && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                    )
                }
            </button>
            {
                isVisible && (
                    <div className="absolute z-0 top-3 left-0 bg-bs-light-gray-10">
                        <div className="w-full">
                            <div className="flex space-between px-8 gap-2">
                                {
                                    (activeFiltersKeys.length > 0) && (
                                        // <Button color={"tertiary"} onClick={resetFilters}>Clear All</Button>
                                        <Button title={"Clear filters"} size="xs" variant="none" onClick={clearAllFilters}/>
                                    )
                                }
                                {
                                    isDirty && (
                                        // <Button color={"primary"} onClick={applyFilters}>Apply Filters</Button>
                                        <Button title={"Apply filters"} size="xs" variant="primary" onClick={(ev) => {
                                            ev.preventDefault()
                                            applyFilters()
                                            setVisibilityState(false)
                                        }}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={clsFilters}>
                {
                    filters.map((category: any, index: number) => {
                        const categoryActiveFiltersCount = countActiveFilters(category?.filters, activeFiltersKeys);
                        const categoryChangeFilterCount = countActiveFilters(category?.filters, changesFilterKeys);

                        const [state, setState] = useState({
                            isOpen: index === 0
                        });

                        const subfilters = _.get(category, 'filters', []);
                        return (
                            <div className="mb-5" key={category.title}>
                                <div className="flex cursor-pointer select-none mb-2.5"
                                     onClick={() => setState({...state, isOpen: !state.isOpen})}>
                                    <p className="uppercase font-semibold text-sm flex-grow">
                                        {category.title}
                                        {
                                            ((categoryChangeFilterCount > 0) || (categoryActiveFiltersCount > 0)) && (
                                                <span
                                                    className={classNames({
                                                        "font-normal py-1 px-2 text-xs ml-2 rounded-md": true,
                                                        "bg-bs-light-gray-300": categoryChangeFilterCount === categoryActiveFiltersCount,
                                                        "bg-bs-light-black text-white": categoryChangeFilterCount !== categoryActiveFiltersCount,
                                                    })}>
                                                {categoryChangeFilterCount}
                                            </span>
                                            )
                                        }
                                    </p>
                                    {
                                        state.isOpen && (
                                            <ChevronUpIcon height={18} width={18}/>
                                        )
                                    }
                                    {
                                        !state.isOpen && (
                                            <ChevronDownIcon height={18} width={18}/>
                                        )
                                    }
                                </div>
                                <div
                                    className="font-normal space-y-3.5"
                                    style={{display: state.isOpen ? "block" : "none"}}>
                                    {
                                        subfilters.map((filter: any) => {
                                            return (
                                                <FilterFactory
                                                    key={filter.key}
                                                    filtersChanges={filtersChanges}
                                                    filtersActive={filtersActive}
                                                    filter={{...filter, addFilter, removeFilter}}
                                                    addFilter={addFilter}
                                                    removeFilter={removeFilter}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}