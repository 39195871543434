import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import _, {flatten, forEach, get} from "lodash";
import {upsert} from "../../../utils/arrays";
import {DataContainerFilters} from "../DataContainerFilters";
import {decode, encode} from "js-base64";
// @ts-ignore
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import {FETCH_FACETS_BY_NAME} from "../queries";

export type TDataContainerFilterCallback = (value: IDataContainerFilter) => void;

export interface IDataContainerFilterValue {
    id: string;
    value: string;
}

export interface IDataContainerFilter {
    type: string;
    key: string;
    value: IDataContainerFilterValue[] | string[] | boolean | string | object;
    sub_filters?: IDataContainerFilter[];
}

export interface IDataContainerSort {
    key: string;
    dir: string;
}

export interface IDataContainerContext {
    filters: any;
    isDirty: boolean;
    filtersActive: IDataContainerFilter[];
    filtersChanges: IDataContainerFilter[];
    activeSort: IDataContainerSort[];
    filtersQuery: any;
    sortBy?: (sort: IDataContainerSort) => void; // @todo make it mandatory
    resetFilters: () => void;
    clearAllFilters: () => void;
    addFilter: TDataContainerFilterCallback;
    removeFilter: TDataContainerFilterCallback;
    removeAndApply: TDataContainerFilterCallback;
    applyFilters: () => void;
}

const DataContainerContext = createContext<IDataContainerContext>(
    {} as IDataContainerContext
);

interface IDataContainerQueryContext {
    filtersQuery: any;
}

const DataContainerQueryContext = createContext<IDataContainerQueryContext>(
    {} as IDataContainerQueryContext
);

function mapFilterTypeToGraphQL(type: string, value: any, key: string): any {
    switch (type) {
        case 'search':
        case 'search-fulltext':
            return {"query_string": {"query": `*${value}*`, "default_field": key}}
        case 'select-and':
            return {"term": {[key]: value}}
        case 'select-or':
            return {"terms": {[key]: value}}
        case 'negate':
            return {"term": {[key]: false}}
        case 'boolean':
            return {"term": {[key]: value}}
        case 'exists':
            return {"exists": {"field": key}}
        case 'exists_nth':
            return {"exists": {"field": key, "nth": value}}
        case 'range':
            return {"range": {[key]: value}} // {"range": { "founded": { "lte": "2022", "gte": "2020" } }
        case 'missing':
            return {
                "bool": {
                    "must_not": {
                        "exists": {
                            "field": key
                        }
                    }
                }
            }
        default:
            return {
                key: 'null',
                value: null
            }
    }
}

function mapFilterToGraphQL(filters: IDataContainerFilter[]) {
    const f = filters.map((f) => {
        return mapFilterTypeToGraphQL(f.type, f.value, f.key)
    })
    return flatten(f);
}

interface IDataContainerProvider {
    children: ReactNode;
    filters: any[]
    sort?: IDataContainerSort[],
    defaultFilters?: any
}

export function DataContainerProvider({children, filters, sort, defaultFilters}: IDataContainerProvider) {
    const [state, setState] = useState({
        filters: filters,
        isDirty: false,
        activeSort: sort as IDataContainerSort[],
        filtersActive: defaultFilters || [] as IDataContainerFilter[],
        filtersChanges: [] as IDataContainerFilter[],
        filtersQuery: defaultFilters || [] as any
    });
    const [searchParams, setSearchParams] = useSearchParams();

    const [fetchFacets, {data, loading, called}] = useLazyQuery(FETCH_FACETS_BY_NAME)

    useEffect(() => {
        if (searchParams.get('f') !== null) {
            const filters: IDataContainerFilter[] = JSON.parse(decode(searchParams.get('f') || '{}'));
            setState({
                ...state,
                filtersQuery: mapFilterToGraphQL(filters),
                filtersActive: filters,
                filtersChanges: filters
            });
        }
    }, [searchParams]);


    useEffect(() => {
        if(searchParams.get('f') === null && defaultFilters?.length > 0) {
            updateSearchParams(defaultFilters)
        }
    }, [defaultFilters, searchParams]);

    const sortBy = (sort: IDataContainerSort) => {
        setState({
            ...state, activeSort: [sort]
        })
    }

    const resetFilters = () => {
        setState({...state, filtersChanges: [], isDirty: false})
    }

    const clearAllFilters = () => {
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL([]),
            filtersChanges: [],
            filtersActive: []
        });

        updateSearchParams([]);
    }

    const updateSearchParams = (filters: IDataContainerFilter[]) => {
        if(filters.length > 0) {
            const qs = encode(JSON.stringify(filters), true);
            searchParams.delete('f');
            searchParams.append('f', qs);
            setSearchParams(searchParams);
        } else {
            searchParams.delete('f');
            setSearchParams(searchParams)
        }
    }

    const applyFilters = () => {
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL(state.filtersChanges),
            filtersActive: state.filtersChanges,
            filtersChanges: state.filtersChanges,
            isDirty: false
        });
        updateSearchParams(state.filtersChanges);
    }

    const addFilter = (filter: IDataContainerFilter) => {
        var filters = upsert(state.filtersChanges, filter, 'key')
        const extras = get(filter, 'sub_filters', [] as IDataContainerFilter[])
        forEach(extras, filter => {
            console.log("filter", filter)
            filters = upsert(filters, {
                type: filter.type, key: filter.key, value: filter.value
            }, 'key')
        })
        setState({
            //@ts-ignore
            ...state, filtersChanges: filters, isDirty: !_.isEqual(filters, state.filtersActive)
        })
    }
    const removeFilter = (filter: any) => {
        const filters = _.filter(state.filtersChanges, (f: any) => {
            return f.key !== filter.key
        })
        setState({
            // @ts-ignore
            ...state, filtersChanges: filters, isDirty: !_.isEqual(filters, state.filtersActive)
        })
    }

    const removeAndApply = (filter: IDataContainerFilter) => {
        const filters = _.filter(state.filtersChanges, (f: any) => {
            return f.key !== filter.key
        })
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL(filters),
            filtersActive: filters,
            filtersChanges: filters,
            isDirty: false
        });
        updateSearchParams(filters);
    }

    // console.log("DataContainerContext", state.filtersChanges)
    return (
        <DataContainerContext.Provider
            value={{...state, resetFilters, addFilter, removeFilter, clearAllFilters, applyFilters, removeAndApply, sortBy}}>
            <div className="flex w-full min-h-0 max-w-[100vw]">
                <DataContainerQueryContext.Provider value={{filtersQuery: state.filtersQuery}}>
                    <div className="w-[calc(100vw-2.5rem)] overflow-scroll min-h-0">
                        {children}
                    </div>
                </DataContainerQueryContext.Provider>
            </div>
        </DataContainerContext.Provider>
    )
}

export function useDataContainerContext() {
    return useContext(DataContainerContext)
}

export function useDataContainerQueryContext() {
    return useContext(DataContainerQueryContext)
}