import React, {useEffect, useMemo, useState} from "react";
import {IFilter} from "./FilterFactory";
import {useLazyQuery} from "@apollo/client";
import {FETCH_FACETS_BY_NAME} from "../queries";
import {get, remove} from "lodash";
import {useCombobox, useMultipleSelection} from "downshift";
import {FilterTitle} from "./fragments/FilterTitle";
import {RxCross1, RxTriangleDown} from "react-icons/rx";
import classNames from "classnames";
import Fuse from "fuse.js"
import {FiCheck} from "react-icons/fi";

interface IFilterDropDown extends IFilter {
    dynamic?: boolean;
    bucket?: string;
    selection?: "single" | "tags";
}

export const FilterDropDown = ({ ...props} : IFilterDropDown ) => {
    const { options = [], title, addFilter, id: filterId, type: filterType, defaultValues, removeFilter, dynamic, bucket } = props
    const [inputValue, setInputValue] = React.useState('')
    const [opts, setOpts] = useState(options || []);
    const [selectedItems, setSelectedItems] = useState([] as { key: string, value: string, title: string }[])
    const fuse = new Fuse(opts, {
        includeScore: true,
        keys: ['title']
    })
    const items = useMemo(() => {
        return (inputValue?.length > 0 ? fuse.search(inputValue).map((f) => f.item) : opts)
    }, [selectedItems, inputValue])
    const [fetch, { loading, data, called }] = useLazyQuery(FETCH_FACETS_BY_NAME, {
        variables: {
            facets: {
                bucket: bucket,
                list: [filterId]
            }
        }
    });

    useEffect(() => {
        if (dynamic) {
            fetch();
        }
    }, [dynamic]);

    useEffect(() => {
        if (called && !loading && data) {
            const o = get(data, 'facets.rows[0].values', []).map((op: any) => {
                return { key: op.key, value: op.key, title: `${op.key} (${op.count})` }
            });
            setOpts(o)
        }
    }, [data, called, loading])

    useEffect(() => {
        if (opts.length > 0) {
            const values = get(defaultValues, 'value', []);
            setSelectedItems(opts.filter((item) => {
                return values.includes(item.key)
            }) || [])
        }
    }, [defaultValues, opts])

    const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
        useMultipleSelection({
            selectedItems: selectedItems,
            onStateChange({ selectedItems: newSelectedItems, type }) {
                switch (type) {
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                    case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                        //@ts-ignore
                        setSelectedItems(newSelectedItems)
                        //@ts-ignore
                        if (newSelectedItems?.length > 0) {
                            //@ts-ignore
                            addFilter({ type: filterType, key: filterId, value: newSelectedItems.map((v) => v.value) })
                        } else {
                            removeFilter({
                                type: filterType, key: filterId, value: []
                            })
                        }
                        break
                    default:
                        break
                }
            },
        })

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox({
        items,
        itemToString(item) {
            //@ts-ignore
            return item ? item.title : ''
        },
        defaultHighlightedIndex: 0, // after selection, highlight the first item.
        selectedItem: null,
        inputValue,
        stateReducer(state, actionAndChanges) {
            const { changes, type } = actionAndChanges

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, // keep the menu open after selection.
                        highlightedIndex: 0, // with the first option highlighted.
                    }
                default:
                    return changes
            }
        },
        onStateChange({
                          inputValue: newInputValue,
                          type,
                          selectedItem: newSelectedItem,
                      }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (newSelectedItem) {
                        if(selectedItems.includes(newSelectedItem)) {
                            remove(selectedItems, newSelectedItem)
                        } else {
                            selectedItems.push(newSelectedItem)
                        }
                        if(selectedItems.length < 1) {
                            removeFilter({
                                type: filterType, key: filterId, value: []
                            })
                        } else {
                            addFilter({ type: filterType, key: filterId, value: selectedItems.map((v) => v.value) })
                        }
                        setSelectedItems(selectedItems)
                        setInputValue('')
                    }
                    break

                case useCombobox.stateChangeTypes.InputChange:
                    //@ts-ignore
                    setInputValue(newInputValue)

                    break
                default:
                    break
            }
        },
    })

    return (
        <div className="w-full">
            <FilterTitle title={title} active={opts.length > 0} loading={loading} />
            <div className="relative w-full">
                <div className="w-full relative flex">
                    <RxTriangleDown className="text-xl absolute right-1.5 top-3.5"/>
                    <input
                        className="w-full mt-2 border py-1.5 outline-none rounded-md placeholder:text-ab-lightteal px-4 placeholder:font-medium"
                        placeholder={opts.length > 0 ? "All" : ""}
                        type="text"
                        disabled={opts.length < 1}
                        {...getInputProps(getDropdownProps({preventKeyAction: isOpen}))}
                    />
                </div>
                <ul
                    className={classNames({
                        "absolute w-inherit bg-white shadow-md border border-gray-500 rounded-lg mt-1 text-ab-darkgreen max-h-80 overflow-scroll p-0 z-10 w-full": true,
                        "hidden": !(isOpen && items.length)
                    })}
                    {...getMenuProps()}
                >
                    {isOpen && items.length >0 &&
                        items
                            .map((item, index) => (
                                <li
                                    className={classNames({
                                        'bg-bs-light-gray-100 underline': highlightedIndex === index,
                                        'py-2 px-3 shadow-sm flex flex-col text-sm w-full cursor-pointer': true,
                                        'font-semibold': selectedItems.includes(item),
                                    })}
                                    key={`${item.key}`}
                                    {...getItemProps({item})}
                                >
                                    <span>
                                        {item.title} {selectedItems.includes(item) && <FiCheck className="inline" />}
                                    </span>
                                </li>
                            ))}
                </ul>
                <div className="w-full flex gap-2 pt-2 flex-wrap">
                    {selectedItems.map(function renderSelectedItem(
                        selectedItemForRender: any,
                        index: number,
                    ) {
                        return (
                            <span
                                className="select-none rounded-md text-sm bg-bs-light-black cursor-pointer text-bs-light-gray-10 px-2 py-1 hover:bg-red-400  gap-1 inline-flex items-center"
                                key={`selected-item-${index}`}
                                {...getSelectedItemProps({
                                    selectedItem: selectedItemForRender,
                                    index,
                                })}
                                onClick={e => {
                                    e.stopPropagation()
                                    removeSelectedItem(selectedItemForRender)
                                }}
                            >
                                {
                                    //@ts-ignore
                                    selectedItemForRender.title
                                }
                                <span className="cursor-pointer text-xs">
                                    <RxCross1/>
                                </span>
                            </span>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}