import React from "react";
import {useDataContainerContext} from "../../interface/DataContainer/context/DataContainerContext";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {Link} from "react-router-dom";
import {Persona} from "../../interface/Persona";
import {PencilIcon} from "@heroicons/react/outline";
import {gql} from "@apollo/client";
import {usePlatformContext} from "../../context/PlatformContext";
import UpsertSector from "../fragments/UpsertSector";
import {DataContainersFiltersHorizontal} from "../../interface/DataContainer/DataContainerFilters";

export default function SectorsContainer() {
    const {filtersQuery} = useDataContainerContext();
    const {addPane, removePaneByID} = usePlatformContext()

    return (
        <section className="px-5">
            <DataContainersFiltersHorizontal/>
            <DataGridGraphQL
                loading={false}
                query={gql`
                        query searchSectors($search: SearchRequest!) {
                            search(search: $search) {
                                items
                                total
                                took
                                cursor
                            }
                        }
                        `}
                variables={{
                    search: {
                        bucket: 'sectors',
                        sort: [
                            {key: "name.keyword", dir: "asc"}
                        ]
                    }
                }}
                root="search"
                property="items"
                filters={filtersQuery}
                entityName="sectors"
                headers={[
                    {
                        title: "Sector Name",
                        dataIndex: "name",
                        key: "name",
                        render: (value) => {
                            return (
                                <Link to={['/organizations/directory/orgs', value.id].join("/")}>
                                    <Persona text={value.name} imageURL={value.profile_image_url}/>
                                </Link>
                            )
                        }
                    },
                    {
                        title: "Organizations",
                        dataIndex: "count",
                        key: "count"
                    },
                    {
                        title: "Last Update",
                        dataIndex: "updated_at",
                        key: "updated_at"
                    },
                    {
                        title: "",
                        dataIndex: "actions",
                        key: "actions",
                        render: (value) => {
                            const id = `edit-${value.id}`;
                            return (
                                <>
                                    <PencilIcon className="w-5 h-5 text-gray-500" onClick={() => {
                                        addPane({
                                            title: `Edit sector ${value.name}`,
                                            id, type: "edit",
                                            onClose: () => removePaneByID(id),
                                            content: <UpsertSector id={value.id.toString()}
                                                                   onCancel={() => removePaneByID(id)}/>
                                        })
                                    }}/>
                                </>
                            )
                        }
                    }
                ]}
                data={[]}
            />
        </section>
    )
}