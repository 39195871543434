import React, {ChangeEvent, useEffect, useState} from "react";
import {FilterTitle} from "./fragments/FilterTitle";
import {IFilter} from "./FilterFactory";
import {get} from "lodash";

/**
 *
 */

interface IFilterRange extends IFilter {
}

export default function FilterRange({...props}: IFilterRange) {
    const {addFilter, removeFilter, title, type, id, defaultValues, extras} = props
    const [values, setValues] = useState(get(defaultValues, 'value', {}))

    const update = (ev: ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [ev.target.name]: extras?.onChange ? extras?.onChange(ev) : ev.target.value})
    }

    useEffect(() => {
        if (get(defaultValues, 'value.lte', undefined) || get(values, 'value.gte', undefined)) {
            setValues(get(defaultValues, 'value', {}))
        }
    }, [defaultValues])
    useEffect(() => {
        if (get(values, 'lte', undefined) || get(values, 'gte', undefined)) {
            addFilter({type, key: id, value: values})
        }
    }, [values])


    return (
        <div className="w-full flex flex-col gap-1">
            <FilterTitle title={title}/>
            <div className="flex items-center flex-col w-full gap-4">
                <div className="flex flex-col flex-grow w-full">
                    <label
                        className="text-sm text-bs-light-gray pt-2">From</label>
                    <input type={extras?.type ? extras.type : "text"}
                           className="border-b border-b-bs-gray bg-transparent px-2.5 py-1.5 outline-none"
                           name="gte"
                           value={extras?.render ? extras?.render(get(values, 'gte', '')) : get(values, 'gte', '')}
                           onChange={update}
                    />
                </div>
                <div className="flex flex-col flex-grow w-full">
                    <label
                        className="text-sm text-bs-light-gray pt-2">To</label>
                    <input type={extras?.type ? extras.type : "text"}
                           className="border-b border-b-bs-gray bg-transparent px-2.5 py-1.5 outline-none"
                           name="lte"
                           value={extras?.render ? extras?.render(get(values, 'lte', '')) : get(values, 'lte', '')}
                           onChange={update}
                    />
                </div>
            </div>
        </div>
    )
}