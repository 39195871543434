import React, {useState} from "react"
import {useCombobox, useMultipleSelection} from "downshift";
import {useFormContext, UseFormRegister, useWatch} from "react-hook-form";
import {StyledLabel, StyledElementContainer, StyledInput} from "../StyledElements";
import {find, get, uniq, uniqBy} from "lodash";
import {styled} from "@briterbridges/elements";
import {FiX, FiPlus} from "react-icons/fi";
import Select from "react-select"

export type IComboBoxValue = {
    id: string | number
    value: string
}

function getFilteredValues(options: IComboBoxValue[], selectedItems: IComboBoxValue[], inputValue: string): IComboBoxValue[] {
    const lowerCasedInputValue: string = inputValue.toLowerCase()

    return options.filter(function (option: IComboBoxValue) {
        return (
            !selectedItems.includes(option) &&
            option.value.toLowerCase().includes(lowerCasedInputValue))
    })
}

function getIDsFromComboBoxValues(values: IComboBoxValue[]): string[] {
    return values.map((v) => v.id.toString())
}

function getComboBoxValuesFromKeys(options: IComboBoxValue[], ids: string[]): IComboBoxValue[] {
    return options.filter((value) => {
        return ids.includes(value.id.toString())
    })
}

export const MultipleComboBoxInput = React.forwardRef<HTMLSelectElement,
    { label: string, options: IComboBoxValue[], inline?: boolean, creating?: boolean } & ReturnType<UseFormRegister<any>>
>(({name, label, options, inline = false, creating = true}, ref) => {

    const {setValue, getValues} = useFormContext()
    const [inputValue, setInputValue] = useState('')
    // const [selectedValues, setSelectedValues] = useState(getComboBoxValuesFromKeys(options, getValues(name) || []))
    const [selectedValues, setSelectedValues] = useState(getValues(name) || [])
    const items = React.useMemo(
        () => getFilteredValues(options, selectedValues, inputValue),
        [selectedValues, inputValue],
    )
    const {getSelectedItemProps, getDropdownProps, removeSelectedItem} =
        useMultipleSelection({
            selectedItems: selectedValues,
            onStateChange({selectedItems: newSelectedItems, type}) {
                switch (type) {
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                    case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                        // setValue(name, getIDsFromComboBoxValues(newSelectedItems || []))
                        setValue(name, newSelectedItems)
                        setSelectedValues(newSelectedItems || [])
                        break
                    default:
                        break
                }
            },
        })

    const {
        isOpen,

        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        selectedItem,
    } = useCombobox({
        items,
        itemToString(item) {
            return item ? item.value : ''
        },
        defaultHighlightedIndex: 0, // after selection, highlight the first item.
        inputValue,
        stateReducer(state, actionAndChanges) {
            const {changes, type} = actionAndChanges

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, // keep the menu open after selection.
                        highlightedIndex: 0, // with the first option highlighted.
                    }
                default:
                    return changes
            }
        },
        onStateChange({
                          inputValue: newInputValue,
                          type,
                          selectedItem: newSelectedItem,
                      }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (newSelectedItem) {
                        const values = uniqBy([...selectedValues, newSelectedItem], 'id')
                        // setValue(name, uniq(values.map((item) => item.id)))
                        setValue(name, values)
                        setSelectedValues(values)
                        setInputValue('')
                    }
                    break

                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(newInputValue || '')
                    break
                default:
                    break
            }
        },
    })

    return (
        <StyledElementContainer inline={inline}
                                style={{position: "relative", display: "block"}}>
            <StyledLabel {...getLabelProps()} inline={inline}>{label} ({selectedValues.length})</StyledLabel>
            <div style={{
                display: "flex", columnGap: "0.5rem", rowGap: "0.25rem", flexWrap: "wrap",
            }}>
                {selectedValues.map((item: IComboBoxValue, index: number) => {
                    return (
                        <p
                            style={{
                                backgroundColor: "rgb(255, 219, 128)",
                                alignSelf: "flex-end", color: "#333", fontSize: "90%",
                                margin: 0, lineHeight: "1rem",
                                fontWeight: 500, textTransform: "uppercase",
                                borderRadius: "0.25rem", display: "flex",
                            }}
                            key={`selected-item-${index}`}
                            {...getSelectedItemProps({selectedItem: item, index})}
                        >
                            <span style={{padding: "0.25rem 0.25rem", display: "inline-block"}}>{item.value}</span>
                            <span
                                style={{
                                    display: "inline-block", alignSelf: "center", paddingRight: "0.25rem",
                                    color: "#000", cursor: "pointer"
                                }}
                                onClick={e => {
                                    e.stopPropagation()
                                    removeSelectedItem(item)
                                }}
                            ><FiX/></span>
                        </p>
                    )
                })}
                <StyledInput
                    {...getInputProps(getDropdownProps({
                        preventKeyAction: isOpen,
                        // onFocus: openMenu
                    }))} key={name}
                    style={{flexGrow: 1, cursor: "pointer"}} inline={inline}
                />
            </div>
            <StyledElementDropdownContainer {...getMenuProps()}>
                {
                    isOpen && options.length > 0 && (
                        <StyledElementDropdownInnerContainer>
                            {
                                isOpen && items.length > 0 && items.map((item, index) => {
                                    return (
                                        <StyledElementDropdownItem selected={selectedItem?.id === item.id}
                                                                   highlightedIndex={highlightedIndex === index}
                                                                   key={`${item.id}${index}`}
                                                                   {...getItemProps({item, index})}
                                        >
                                            <span>{item.value} ({item?.id?.toString().substring(0, 8)})</span>
                                        </StyledElementDropdownItem>
                                    )
                                })
                            }
                            {
                                creating && isOpen && items.length < 1 && (
                                    <StyledElementDropdownItem style={{ display: "flex"}}>
                                        <span style={{display: "block"}}><FiPlus /></span>
                                        <span style={{display: "block"}}>Create new {inputValue}</span>
                                    </StyledElementDropdownItem>
                                )
                            }
                        </StyledElementDropdownInnerContainer>
                    )
                }

            </StyledElementDropdownContainer>
        </StyledElementContainer>
    )
})

export interface SelectComboBoxProps {
    defaultValue: any
    options: any[]
    onChange: any
}

export const SingleComboBoxInput = React.forwardRef<HTMLSelectElement,
    { label: string, options: IComboBoxValue[], inline?: boolean } & ReturnType<UseFormRegister<any>>
>(({name, label, options, inline = false}, ref) => {

    const {setValue, getValues} = useFormContext()

    const [searchText, setSearchText] = useState('')

    const initialSelectedItem = find(options,{id: getValues(name)})

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,

        highlightedIndex,
        getItemProps,
        selectedItem,
        openMenu,
    } = useCombobox({
        selectedItem: initialSelectedItem,
        items: options,
        itemToString(item) {
            return item ? item.value : ''
        },
        onStateChange({
                          inputValue: newInputValue,
                          type,
                          selectedItem: newSelectedItem,
                      }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (newSelectedItem) {
                        setValue(name,newSelectedItem)
                    }
                    break

                case useCombobox.stateChangeTypes.InputChange:
                    setSearchText(newInputValue || '')
                    break
                default:
                    break
            }
        },
    })
    return (
        <StyledElementContainer inline={inline}>
            <StyledLabel {...getLabelProps()} inline={inline}>{label}</StyledLabel>
            <StyledInput {...getInputProps({
                onFocus: openMenu
            })} inline={inline}/>
            <StyledElementDropdownContainer {...getMenuProps()}>
                {
                    isOpen && options.length > 0 && (
                        <StyledElementDropdownInnerContainer>
                            {
                                isOpen && options.length > 0 &&
                                options.filter((it: any) => it.value.toLowerCase().includes(searchText))
                                .map((item, index) => (
                                    <StyledElementDropdownItem selected={selectedItem === item}
                                                               highlightedIndex={highlightedIndex === index}
                                                               key={`${item.value}${index}`} {...getItemProps({
                                        item,
                                        index
                                    })}>
                                        <span>{item.value}</span>
                                    </StyledElementDropdownItem>
                                ))
                            }
                        </StyledElementDropdownInnerContainer>
                    )
                }
            </StyledElementDropdownContainer>
        </StyledElementContainer>
    )
})

const StyledElementDropdownContainer = styled("ul", {
  position: "relative", zIndex: "20", backgroundColor: "#fff",
    maxHeight: "30vh", overflow: "auto", width: "100%"
})

const StyledElementDropdownInnerContainer = styled("div", {
    border: "1px solid #eee", width: "100%", height: "100%", zIndex: 20,
    borderTop: "0", boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"
})

const StyledElementDropdownItem = styled("li", {
    backgroundColor: "transparent",
    fontWeight: 300,
    display: "flex",
    padding: "0.15rem 0.5rem",
    flexDirection: "column",
    cursor: "pointer",
    variants: {
        highlightedIndex: {
            true: {
                backgroundColor: "#eee",
                color: "#000",
                fontWeight: 500
            }
        },
        selected: {
            true: {
                fontWeight: 600
            }
        }
    }
})
