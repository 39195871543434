import React from "react";
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {Tabs} from "../../interface/Tabs/Tabs";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {FETCH_SEARCH} from "../../queries";
import {PencilIcon} from "@heroicons/react/outline";
import {format} from "date-fns";
import {usePlatformContext} from "../../context/PlatformContext";
import {useNavigate} from "react-router-dom";
import {DataContainersFiltersHorizontal} from "../../interface/DataContainer/DataContainerFilters";

const filters: any = [
    {
        title: "General",
        filters: [
            {
                type: "search",
                title: "Event name",
                key: "title",
                id: "title"
            },
            {
                type: "select-or",
                title: "Sectors",
                key: "sectors_of_interests.name.keyword",
                id: "sectors_of_interests.name.keyword",
                dynamic: true,
                bucket: 'initiatives',
                options: []
            },
            {
                type: "select-or",
                title: "Type",
                key: "type.keyword",
                id: "type.keyword",
                dynamic: true,
                bucket: 'initiatives',
                options: []
            },
            {
                type: "select-or",
                title: "Countries of Interest",
                key: "countries_of_interests.name.keyword",
                id: "countries_of_interests.name.keyword",
                dynamic: true,
                bucket: 'initiatives',
                options: []
            },
            {
                type: "select-or",
                title: "Sponsors",
                key: "sponsors.name.keyword",
                id: "sponsors.name.keyword",
                dynamic: true,
                bucket: 'initiatives',
                options: []
            },
        ]
    }]

const InitiativesDirectoryContainer = () => {
    const {filtersQuery} = useDataContainerContext();
    const {addPane, removePaneByID} = usePlatformContext();
    const navigate = useNavigate()
    return (
        <section className="px-5">
            <DataContainersFiltersHorizontal/>
            <div className="first:py-2.5">
                <DataGridGraphQL
                    loading={false}
                    query={FETCH_SEARCH}
                    root="search"
                    property="items"
                    filters={filtersQuery}
                    variables={{
                        "search": {
                            "bucket": "initiatives",
                            "sort": [{key: "title.keyword", dir: "asc"}],
                            "query": {
                                "filters": [{"term": {"enabled": true}}]
                            }
                        }
                    }}
                    entityName="initiatives"
                    data={[]}
                    headers={[
                        {
                            title: "Event Name",
                            dataIndex: "title",
                            key: "title",
                            render: (data) => {
                                return (
                                    <div className="flex gap-2">
                                        <div className="flex gap-2.5">
                                            <PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {
                                                navigate(["/initiatives", data.id, "edit"].join("/"))
                                            }}/>
                                        </div>
                                        <p>{data.title}</p>
                                    </div>
                                )
                            }
                        },
                        {
                            title: "Type",
                            dataIndex: "type",
                            key: "type"
                        },
                        {
                            title: "Organizers",
                            dataIndex: "organizers",
                            key: "organizers",
                            // render: (value) => {
                            //     const organizers = get(value, 'organizers', []) || []
                            //     console.log("orgs",organizers)
                            //     const values = organizers.map((s: any) => ({value: s.name}));
                            //     return <DataGridArrayCell value={values}/>
                            // }
                        },
                        {
                            title: "Sponsors",
                            dataIndex: "sponsors",
                            key: "sponsors",
                            // render: (value) => {
                            //     const values = get(value, 'sponsors', []).map((s: any) => ({value: s.name}));
                            //     return <DataGridArrayCell value={values}/>
                            // }
                        },
                        {
                            title: "Sectors",
                            dataIndex: "sectors_of_interests",
                            key: "sectors_of_interests",
                            // render: (value) => {
                            //     const res = get(value, 'sectors_of_interests', [])
                            //     const values = res ? res.map((s: any) => ({value: s.name})) : []
                            //     return <DataGridArrayCell value={values}/>
                            // }
                        },
                        {
                            title: `Time (${format(new Date(), 'O')})`,
                            dataIndex: "duration",
                            key: "duration",
                            render: (v) => {
                                return (
                                    <div className="flex flex-col text-sm">
                                        <p>
                                            <span className="uppercase text-bs-light-gray mr-1">Start</span>
                                            {format(new Date(v.start_at), 'Pp O')}
                                        </p>
                                        <p>
                                            <span className="uppercase text-bs-light-gray mr-1">Finish</span>
                                            {format(new Date(v.end_at), 'Pp O')}
                                        </p>
                                    </div>
                                )
                                return [
                                    format(new Date(v.start_at), 'Pp'),
                                    format(new Date(v.end_at), 'Pp O')
                                ].join(" to ")
                            }
                        },
                        {
                            title: "Country of Interest",
                            dataIndex: "countries_of_interests",
                            key: "countries_of_interests",
                            // render: (value) => {
                            //     const res = get(value,"countries_of_interests",[])
                            //     const values = res ? res.map((s: any) => ({value: s.name})) : []
                            //     return <DataGridArrayCell value={values}/>
                            // }
                        }
                    ]}
                />
            </div>
        </section>
    )
}

export default function InitiativesContainer() {
    const nav = {
        "directory": {
            title: 'Directory',
            key: 'directory',
            pane: <InitiativesDirectoryContainer/>
        }
    }
    return (
        <DataContainerProvider filters={filters}>
            <div className="flex-grow flex flex-row">
                <div className="flex-grow">
                    <Tabs
                        title="Initiatives"
                        index={'directory'}
                        tabs={nav}
                    />
                </div>
            </div>
        </DataContainerProvider>
    )
}
