import React, { useState, useRef } from "react";
import { DropdownButtonContainer, DropdownButtonMenu, DropdownButtonItem } from "@briterbridges/elements"
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";

import { useOnClickOutside } from "usehooks-ts";
import {
    ArrowDownIcon,
    ArrowUpIcon,
} from "@heroicons/react/outline";
import { IDataGrid, IDataGridHeader, IDataGridOptions } from "./DataGridTypes";
import { ResultsPerPage} from "./DataGridPagination";
import { Button } from "../Button/Button";
import {find, has} from "lodash"

import { DataGridRow } from "./Row";
import noDataImage from "./nodata.svg"

interface IDataGridHeadersSort {
    key: string;
    dir: string;
}

interface IDataGridHeaders {
    columns: IDataGridHeader[];
    options: IDataGridOptions;
    sortBy?: (sort: IDataGridHeadersSort) => void;
    sort?: IDataGridHeadersSort[]
    isRowSelected?: boolean;
    onSelectAll?: any
}

// the header receives headers
function DataGridHeaders({ ...props }: IDataGridHeaders) {
    const { options, columns, sortBy, sort, onSelectAll, isRowSelected } = props
    return (
        <div role="rowheader" className="contents"
            style={{
                gridRowStart: 1
            }}>
            {
                options.isSelectable && (
                    <div role="gridcell"
                        key={`DataGridHeader__header__item__${1}`}
                        className="bg-bs-light-gray-50 uppercase text-sm  text-bs-light-gray px-2 flex justify-center px-2 py-3 first:rounded-l-sm last:rounded-r-sm"
                        style={{ gridColumnStart: 1 }}>
                        <input type="checkbox" onChange={onSelectAll} checked={isRowSelected} />
                    </div>
                )
            }
            {columns.map((column: IDataGridHeader, index: number) => {
                const isSortable = column?.sortingKey !== undefined
                const sortValue = find(sort, { key: column.sortingKey })
                return (
                    <div role="gridcell"
                        key={`DataGridHeader__header__item__${index + (options.isSelectable ? 2 : 1)}`}
                        onClick={(ev) => {
                            ev.preventDefault()
                            if (column?.sortingKey) {
                                console.log(column.sortingKey, sortValue)
                                if (sortBy && column.sortingKey && sortValue?.dir === "desc") {
                                    sortBy({ key: column.sortingKey, dir: "asc" })
                                } else if (sortBy && column.sortingKey) {
                                    sortBy({ key: column.sortingKey, dir: "desc" })
                                }

                            }
                        }}
                        className="bg-bs-light-gray-50 uppercase text-sm flex items-center cursor-pointer gap-1.5 text-bs-light-gray px-2 py-3 first:rounded-l-lg last:rounded-r-lg"
                        style={{ gridColumnStart: index + (options.isSelectable ? 2 : 1) }}>
                        {
                            isSortable && sortValue && sortValue.dir === "asc" && (
                                <ArrowUpIcon className="w-3 h-3 text-black" />
                            )
                        }
                        {
                            isSortable && sortValue && sortValue.dir === "desc" && (
                                <ArrowDownIcon className="w-3 h-3 text-black" />
                            )
                        }
                        {
                            isSortable && !sortValue && (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                    stroke="currentColor" className="w-3 h-3">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                                </svg>
                            )
                        }
                        {
                            column.renderHeader && column.renderHeader(column)
                        }
                        {
                            !column.renderHeader && column.title
                        }
                    </div>
                )
            })}
        </div>
    )
}

const DEFAULT_OPTIONS = {
    isSearchingEverywhere: true,
    isSelectable: true
}

const DataGridContainer = styled.div`
	user-select: none;
	width: 100%;
`

const DataGridInnerContainer = styled.div`
	display: flex; 
	position: absolute; 
	right: 0; 
	justify-content: space-between; 
	align-items: center; 
`

export function DataGrid({ ...props }: IDataGrid) {
    const {
        headers = [],
        allHeaders,
        data = [],
        options,
        entityName,
        count,
        took,
        sort,
        sortBy,
        pages = 1,
        currentPage = 1,
        rowsPerPage = 10,
        loading = false,
        onChangeRowsPerPage = () => {
        },
        onPagination = () => {
        },
        onColumnsChange,
        selectedRows,
        onSelectAllRows,
        onSelectRow,
        rowHeight,
    } = props
    const [opts, setOpts] = useState({ ...DEFAULT_OPTIONS, ...options });
    const columnsNumber: number = headers.length + (opts.isSelectable ? 1 : 0);
    const rowsNumber: number = data.length;
    const rHeight = rowHeight || 40;

    const isColumnSelected = (col: IDataGridHeader) => headers.some(header => header.key === col.key);
    const isRowSelected = (row: any) => selectedRows ? selectedRows.includes(row) : false;
    const isTableSelected = () => selectedRows ? selectedRows.length === data.length && selectedRows.length !== 0 : false;

    return (
        <DataGridContainer>
            <div className="flex justify-center items-center min-h-0 w-full">
                <div className="flex-grow flex items-center gap-2 pb-2.5">
                    <div className="flex-grow">
                        {
                            loading && (
                                <p>
                                    <span>Loading..</span>
                                </p>
                            )
                        }
                        {
                            !loading && (
                                <p className="text-sm">
                                    <span>Show  <ResultsPerPage current={rowsPerPage} onChange={onChangeRowsPerPage}/> of {count === 10000 ? "more than 10k" : count} {entityName?.toLowerCase()}</span>
                                    <span className="mx-2.5 text-xs text-bs-light-gray">(in {took} ms)</span>
                                </p>
                            )
                        }
                    </div>
                    <ColumnView options={allHeaders === undefined ? [] : allHeaders} handleColumnChange={onColumnsChange === undefined ? () => { } : onColumnsChange} isColumnSelected={isColumnSelected} />
                </div>
                <div className="flex justify-center items-center gap-5">
                    {
                        options?.isSearchingEverywhere && (
                            <div className="mx-4 self-center border py-2 px-4">
                                <input
                                    type="checkbox"
                                    checked={opts.isSearchingEverywhere}
                                    onChange={() => setOpts({ ...opts, isSearchingEverywhere: !opts.isSearchingEverywhere })}
                                /> Search Everywhere
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="h-fit min-w-max w-full overflow-x-scroll">
                <div role="grid" className="grid border-b-bs-light-gray-100 border-b striped w-auto"
                    style={{
                        width: "100%",
                        overflow: 'hidden',
                        gridTemplateColumns: opts.isSelectable ? `${rHeight}px minmax(250px,auto) repeat(${columnsNumber - 1}, minmax(75px,auto))` : `minmax(250px,auto) repeat(${columnsNumber}, minmax(75px,auto))`,
                        gridTemplateRows: `auto repeat(${rowsNumber}, ${rHeight}px)`,
                    }}>
                    <DataGridHeaders columns={headers} options={opts} sortBy={sortBy} sort={sort} onSelectAll={onSelectAllRows} isRowSelected={isTableSelected()} />
                    {
                        data.map((row, index) =>
                            <DataGridRow
                                options={opts}
                                key={`DataGridRow__row__${index}`}
                                headers={headers}
                                row={row}
                                isRowSelected={isRowSelected(row)}
                                onSelectRow={(row: any) => {
                                    if (onSelectRow) {
                                        onSelectRow(row)
                                        console.log("selected row", row)
                                    }
                                }}
                                rowNumber={index} />)
                    }
                </div>
                {
                    data.length < 1 && !loading && (
                        <div className="text-center py-12 w-full flex flex-col justify-center">
                            <img src={noDataImage} className="h-56 text-center text-bs-light-primary" alt="No results from this query" />
                            <p>No results from this query. Try to reduce the filters.</p>
                        </div>
                    )
                }
            </div>
        </DataGridContainer>
    )
}


export interface ColumnViewProps {
    options: IDataGridHeader[]
    handleColumnChange: (col: IDataGridHeader) => void;
    isColumnSelected: (col: IDataGridHeader) => boolean;
}

export const ColumnView = ({ ...props }: ColumnViewProps) => {
    const { options, handleColumnChange, isColumnSelected } = props

    const [state, setState] = React.useState({
        isOpen: false
    });
    const ref = useRef(null)
    const handleClickOutside = () => {
        setState({ ...state, isOpen: false })
    }

    useOnClickOutside(ref, handleClickOutside)

    return (
        <DropdownButtonContainer ref={ref}>
            <Button title={"Select columns"} variant="none" size="sm"
                onClick={() => setState({ ...state, isOpen: !state.isOpen })}
                iconSuffix={state.isOpen ? <FaChevronDown height={30} width={30} /> : <FaChevronUp height={30} width={30} />}
            />
            {
                state.isOpen && (
                    <DropdownButtonMenu style={{ width: "100%" }}>
                        {options.map((o: any) => {
                            return (
                                <DropdownButtonItem
                                    key={o.key}
                                    onClick={(ev) => {
                                        handleColumnChange(o)
                                    }}>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", width: "100%" }}>
                                        <input type="checkbox" checked={isColumnSelected(o)} onChange={() => console.log("onChange")} />
                                        {o.key}
                                    </div>
                                </DropdownButtonItem>
                            )
                        })}
                    </DropdownButtonMenu>
                )
            }
        </DropdownButtonContainer>
    )
}





