import {gql} from "@apollo/client";

export const DELETE_ORGANIZATION_BY_ID = gql`
    mutation deleteOrganization($id: ID!) {
      deleteOrganization(id: $id) {
        is_successful
      }
    }
`

export const DELETE_EMPLOYEE = gql`
	mutation deleteEmployee($id: ID) {
		removeEmployee(id: $id) {
			id
		}
	}
`

export const FETCH_COMPANY_BY_ID = gql`
query organization($id: ID) {
  organization(id: $id) {
     id
     name
     profile_image_url
     type
     enabled
     operating_status
      ownership_structure
      incorporation_number
     description
     primary_email_contact
      primary_phone_number
     business_models {
        id
        value: name
     }
     created_at
     updated_at
     impact {
        female_cofounder
        assistive_tech
        rural_focused
        female_focused
        youth_focused
        fifty_local
        elderly_people
        zero_emissions
        informal_sector
        bop
        employment_creation
     }
      ops_cities {
          id
          name
          value: name
      }
     ops_countries {
        id
        name
        value: name
     }
      employees {
          id
          role
          person {
              id
              first_name
              other_names
              gender
          }
      }
     offices {
        id
        is_hq_regional
        is_hq_primary
         address
        city {
            id
            name
            subdivision : sub_division {
                id
                name
                country {
                    id 
                    name
                    code
                }
            }
        }
     }
     deals {
        id
        date
        equity
        type
        amount
        stage
        investors {
            id
            name
            profile_image_url
        }
     }
     investments {
        id
        date
        equity
        type
        amount
        stage
        target {
            id
            name
            profile_image_url
        }
        investors {
            id
            name
            profile_image_url
        }
     }
     sectors {
       id
       value: name
     }
     sdgs {
        id
        value: name
     }
     sub_sectors {
        id
        value: name
     }
     technologies {
        id
        value: name
     }
     founded
     socials {
        website
        facebook
        instagram
        youtube
        twitter
        linkedin
        angellist
    }
  }
}
`

export const FETCH_COMPANIES = gql`
query search($search: SearchRequest!) {
    search(search: $search) {
        items
        total
        took
        cursor
    }
}
`

export const UPDATE_LOCS_BY_ORG_ID = gql`
    mutation updateLocsByOrganizationID($id: ID!, $offices: [InputUpsertOffice!]!) {
        updateLocsByOrganizationID(id: $id, offices: $offices) {
            id
        }
    }
`

export const UPSERT_ORGANIZATION = gql`
    mutation upsertOrganization($id: ID, $input: InputUpdateOrganizationRequest) {
        upsertOrganization(id: $id, input: $input) {
            id
        }
    }
`

export const FETCH_OFFICES_BY_ORG_ID = gql`
    query organization($id: ID!) {
        organization(id: $id) {
            id
            name
            description
            enabled
            type
            offices {
                id
                is_hq_regional
                is_hq_primary
                is_office_regional
                address
                country {
                    id
                    name
                }
                city {
                    id
                    name
                    sub_division {
                        id
                        name
                        country {
                            id
                            name
                            alpha2
                        }
                    }
                }
            }
        }
    }
`

export const GET_DELETED_ORGS = gql`
    query getDeletedOrganizations($filters: FiltersInput, $sort: UserSorting, $limit: Int, $offset: Int) {
        getDeletedOrganizations(filters: $filters, sort: $sort, limit: $limit, offset: $offset) {
            total: count,
            data {
                id
                specifier
                name
                description
                primary_email_contact
                primary_phone_number
                profile_image_url
                number_employees
                operating_status
                founded
                updated_at
                created_at
                ownership_structure
                incorporation_number
                ops_cities {
                    id
                    value: name
                }
                ops_countries {
                    id
                    value: name
                }
                offices {
                    id
                    is_hq_regional
                    is_hq_primary
                    is_office_regional
                    address
                    country {
                        id
                        name
                    }
                    city {
                        id
                        name
                        sub_division {
                            id
                            name
                            country {
                                id
                                name
                                alpha2
                            }
                        }
                    }
                }
                business_models {
                    id
                    value: name
                }
                type
                enabled
                sectors {
                    id
                    value: name
                    name
                }
                sub_sectors {
                    id
                    value: name
                }
                technologies {
                    id
                    value: name
                }
                sdgs {
                    id
                    value: name
                }
                impact {
                    female_cofounder
                    assistive_tech
                    rural_focused
                    female_focused
                    youth_focused
                    fifty_local
                    elderly_people
                    zero_emissions
                    informal_sector
                    bop
                    employment_creation
                }
                socials {
                    twitter
                    website
                    facebook
                    instagram
                    angellist
                    linkedin
                    youtube
                }
                agbase_category {
                    id
                    value: name
                }
                agbase_value_chain {
                    id
                    value: name
                }
                agbase_visibility {
                    id
                    value: name
                }
            }
        }
    }
`

export const GET_ORG = gql`
    query organization($id: ID!) {
        organization(id: $id) {
            id
            specifier
            name
            description
            primary_email_contact
            primary_phone_number
            profile_image_url
            number_employees
            operating_status
            founded
            ownership_structure
            incorporation_number
            ops_cities {
                id
                value: name
            }
            ops_countries {
                id
                value: name
            }
            offices {
                id
                is_hq_regional
                is_hq_primary
                is_office_regional
                address
                country {
                    id
                    name
                }
                city {
                    id
                    name
                    sub_division {
                        id
                        name
                        country {
                            id
                            name
                            alpha2
                        }
                    }
                }
            }
            business_models {
                id
                value: name
            }
            type
            enabled
            sectors {
                id
                value: name
            }
            sub_sectors {
                id
                value: name
            }
            technologies {
                id
                value: name
            }
            sdgs {
                id
                value: name
            }
            impact {
                female_cofounder
                assistive_tech
                rural_focused
                female_focused
                youth_focused
                fifty_local
                elderly_people
                zero_emissions
                informal_sector
                bop
                employment_creation
            }
            socials {
                twitter
                website
                facebook
                instagram
                angellist
                linkedin
                youtube
            }
            agbase_category {
                id
                value: name
            }
            agbase_value_chain {
                id
                value: name
            }
            agbase_visibility {
                id
                value: name
            }
        }
    }
`;


export const FETCH_SECTORS = gql`
query sectors($filters: FiltersInput, $offset: Int, $limit: Int) {
    sectors (offset: $offset, limit: $limit, filters: $filters) {
        data {
            id
            name
            enabled
            updated_at
            created_at
        }
        count
        pages
        current
    }
}
`

export const FETCH_SUBSECTORS = gql`
query sectors($filters: FiltersInput, $offset: Int, $limit: Int) {
    sectors (offset: $offset, limit: $limit, filters: $filters) {
        data {
            id
            name
            enabled
            updated_at
            created_at
        }
        count
        pages
        current
    }
}
`


export const RESTORE_ORG_BY_ID = gql`
    mutation restoreOrganizationByID($id: ID!) {
        restoreOrganizationByID(id: $id) {
            id
        }
    }   
`