import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router";
import {gql, useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ORG} from "../companies/queries";
import {get} from "lodash";
import {FormProvider, useForm} from "react-hook-form";
import {InputText} from "../../interface/Form/Input/TextInput";
import {SingleComboBoxInput} from "../../interface/Form/Input/ComboBoxInput";
import {CheckboxInput} from "../../interface/Form/Input/Inputs";
import {DateInput} from "../../interface/Form/Input/DateInput";
import { useFlash } from "../../context/FlashContext";

export const MUTATION_UPSERT_EMPLOYEE = gql`
    mutation upsertEmployee($id: ID!,$input: UpsertEmployeeInput!) {
        upsertEmployee(id: $id,input: $input) {
            id
        }
    }
`

export const AddEmployeeContainer = () => {

    const {id} = useParams()
    const [debug,setDebug] = useState({})
    const [currentlyWorking,setCurrentlyWorking] = useState(false)

    const flash = useFlash()
    const navigate = useNavigate()

    const [fetchOrganization, { data, loading }] = useLazyQuery(GET_ORG,
        {
        variables: {
            id: id
        }
    })

    const [upsertEmployee,{data: upsertEmployeeData,loading: upsertEmployeeLoading}] = useMutation(MUTATION_UPSERT_EMPLOYEE)

    useEffect(() => {
        if(id) {
            fetchOrganization().then((res) => console.log("res",res)).catch((err) =>
                console.log("err",err)
            )
        }
    }, []);

    const methods = useForm({})

    const onSubmit = (data : any) => {
        // todo : normalize data
        const normalized = {...data,gender: data.gender ? data.gender.id : "0",ethnicity: data.ethnicity ? data.ethnicity.id : "other"}
        delete normalized.current
        //setDebug(normalized)

        if(currentlyWorking) {
            delete normalized.end_date
        }

        upsertEmployee({variables: {
            id: id,
            input: normalized
        }}).then((res) => {
            console.log("res",res)
            flash("employee added successfully","success")
            navigate(-1)
        }).catch((err) => {
            console.log("err",err)
            flash(`${err.message}`,"error")
        })
    }

    const watchCurrentRole = methods.watch("current")


    useEffect(() => {
        if(watchCurrentRole) {
            setCurrentlyWorking(true)
            console.log("no end date")
            methods.register("end_date")
        } else {
            setCurrentlyWorking(false)
            console.log("end date required")
            methods.unregister("end_date")
        }
    },[methods.register,methods.unregister,watchCurrentRole])

    return (
        <div className={"p-8 w-full"}>
            <p className="text-2xl font-bold pb-2">Add Employee to organization</p>
            <p className="text-sm md:text-lg xs:text-2xl sm:text-xl pb-4">Adding employee to organization {get(data, "organization.name")}</p>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((e) => {
                    onSubmit(e)
                } )}>
                    <div className="grid md:grid-cols-1 gap-4 lg:grid-cols-3">
                        <div className={"w-full"}>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"First Name"}
                                    register={methods.register}
                                    required={true}
                                    name={"first_name"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className="pb-2">
                                <InputText
                                    label={"Other Names"}
                                    register={methods.register}
                                    required={false}
                                    name={"other_names"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Email"}
                                    register={methods.register}
                                    required={false}
                                    name={"email"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={""}>
                                <SingleComboBoxInput
                                    {...methods.register("gender")}
                                    name={"gender"}
                                    label={"Gender"}
                                    options={[
                                        {
                                            id: "0",
                                            value: "Male"
                                        },
                                        {
                                            id: "1",
                                            value: "Female"
                                        },
                                        {
                                            id: "2",
                                            value: "Non-Binary"
                                        },
                                        {
                                            id: "3",
                                            value: "Prefer not to Say"
                                        },
                                    ]}
                                    onBlur={async () => {
                                    }}
                                    onChange={async () => {
                                    }}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <SingleComboBoxInput
                                    {...methods.register("ethnicity")}
                                    name={"ethnicity"}
                                    label={"Ethnicity"}
                                    options={[
                                        {
                                            id: "african",
                                            value: "African"
                                        },
                                        {
                                            id: "hispanic",
                                            value: "Hispanic"
                                        },
                                        {
                                            id: "middle-eastern-north-african",
                                          value: "Middle Eastern & North African"
                                        },
                                        {
                                            id: "asian",
                                            value: "Asian"
                                        },
                                        {
                                            id: "white",
                                            value: "White"
                                        },
                                        {
                                            id: "other",
                                            value: "Other"
                                        },
                                    ]}
                                    onBlur={async () => {
                                    }}
                                    onChange={async () => {
                                    }}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Education University"}
                                    register={methods.register}
                                    required={false}
                                    name={"education_university"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Education Degree"}
                                    register={methods.register}
                                    required={false}
                                    name={"education_degree"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Education Level"}
                                    register={methods.register}
                                    required={false}
                                    name={"education_level"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Role"}
                                    register={methods.register}
                                    required={false}
                                    name={"role"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <DateInput
                                    required
                                    register={methods.register}
                                    label={"Start Date"}
                                    type={"date"}
                                    formatLayout={"yyyy-MM-dd"}
                                    getValues={methods.getValues}
                                    name={"start_date"}
                                    setValue={methods.setValue} />
                            </div>
                            <div className={"py-7"}>
                                    <CheckboxInput register={methods.register} label={"Currently working in this role"}
                                                   name={"current"}/>
                            </div>
                            <div className={"pb-2"}>
                                {!currentlyWorking ?
                                    <div className="mb-6">
                                        <DateInput
                                            required
                                            register={methods.register}
                                            label={"End Date"}
                                            type={"date"}
                                            formatLayout={"yyyy-MM-dd"}
                                            getValues={methods.getValues}
                                            name={"end_date"}
                                            setValue={methods.setValue}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"LinkedIn"}
                                    register={methods.register}
                                    required={false}
                                    name={"socials.linkedin"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"X(Formerly Twitter)"}
                                    register={methods.register}
                                    required={false}
                                    name={"socials.twitter"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Instagram"}
                                    register={methods.register}
                                    required={false}
                                    name={"socials.instagram"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Facebook"}
                                    register={methods.register}
                                    required={false}
                                    name={"socials.facebook"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Website"}
                                    register={methods.register}
                                    required={false}
                                    name={"socials.website"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pt-8"}>
                                <button type="submit"
                                        disabled={upsertEmployeeLoading}
                                        className="border-1 py-2.5 px-2.5 bg-bs-light-primary rounded-md font-semibold">
                                            {upsertEmployeeLoading ? "Adding...." : "Add Employee"}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>

            <pre className={"pt-2"}>
                {JSON.stringify(debug, null, 4)}
            </pre>
        </div>
    )
}